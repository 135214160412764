import { EpisodeScheduleReducer } from 'reducers/episodeSchedule';
import { getEpisodeTimingVersion } from 'selectors/episodeSelectors';
import { QzmEpisodeAverageOffset } from 'types/api/episodeApi';
import { getClientTime } from 'utils/clientTime';
import { fetchEpisode } from './episodeActions';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';

// Async actions
export const playWithAverageOffset =
  (episodeCode: string): ThunkAction<Promise<void>> =>
  async (dispatch, _getState, { api }) => {
    const { offset } = await api.get<QzmEpisodeAverageOffset>(
      `talpa/episodes/${episodeCode}/averageoffset`,
    );
    const appState = _getState();
    const timingVersion = getEpisodeTimingVersion(appState);

    dispatch(
      setSchedule({
        syncType: 'average',
        offset,
        offsetVersion: timingVersion,
        syncTime: getClientTime(),
      }),
    );
  };

export const recalculateTiming = (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const { audioSync, episodeSchedule, episode } = getState();

  if (
    !episode ||
    episodeSchedule.blockIndex === -1 ||
    audioSync.isSyncing ||
    episodeSchedule.syncType === 'audioSync' ||
    episodeSchedule.syncType === 'audioSyncReset'
  ) {
    return;
  }

  const newEpisodeState = await dispatch(fetchEpisode());

  if (newEpisodeState && newEpisodeState.blockIndex === -1) {
    return;
  }

  dispatch(playWithAverageOffset(episode.episodeCode));
};

// Sync actions
export const setSchedule = (payload: Partial<EpisodeScheduleReducer>) =>
  createActionWithPayload('@episodeSchedule/SET_SCHEDULE', payload);

export const setSyncType = (type: 'average' | 'audioSync') =>
  createActionWithPayload('@episodeSchedule/SYNC_TYPE', type);

export const emptyCurrentScheduleEvent = () =>
  createAction('@episodeSchedule/EMPTY_CURRENT_SCHEDULE_EVENT');

export const clearSchedule = () => createAction('@episodeSchedule/CLEAR');

export type ScheduleActions = ReturnType<
  typeof setSchedule | typeof setSyncType | typeof clearSchedule | typeof emptyCurrentScheduleEvent
>;
