import MenuToggle from 'components/MenuToggle/MenuToggle';
import TopBar from 'components/TopBar';
import { useBreakState } from 'hooks/useBreak';
import React from 'react';
import { useSelector } from 'react-redux';
import AudioSyncScreen from 'screens/AudioSync';
import InteractionScreen from 'screens/Interaction/InteractionScreen';
import { getSchedule, getStartTime } from 'selectors/episodeScheduleSelectors';
import { getEpisode, isBreakActive } from 'selectors/episodeSelectors';
import CommercialBreakScreen from './CommercialBreakScreen';

const GameScreen = () => {
  const episode = useSelector(getEpisode);
  const schedule = useSelector(getSchedule);
  const startTime = useSelector(getStartTime);

  const { currentEventId, currentEventPhase, blockEnded, syncType } = schedule || {};
  const activeBreak = useBreakState(useSelector(isBreakActive), currentEventId, currentEventPhase);

  if (!episode) {
    return null;
  }

  switch (episode.state) {
    case 'BROADCAST':
      if (episode.canAudioSync && blockEnded && (!syncType || syncType === 'audioSyncReset')) {
        return <AudioSyncScreen />;
      }

      if (activeBreak) {
        return <CommercialBreakScreen />;
      }

      if (currentEventId && currentEventPhase) {
        return (
          <InteractionScreen
            eventId={currentEventId}
            eventPhase={currentEventPhase.name}
            startTime={startTime}
            episodeCode={episode.episodeCode}
          />
        );
      }
  }

  return <TopBar logo left={<MenuToggle />} />;
};

export default GameScreen;
