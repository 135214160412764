import Box from 'components/Box';
import CategoryIcon from 'components/CategoryIcon';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getNextEventTime } from 'selectors/eventsSelectors';
import './NextQuestionLoader.css';
import NextQuestionTimeBar from './NextQuestionTimeBar';

interface Props {
  categoryUrl: string;
  categoryText: string;
  startTime?: number;
}

const isNumber = (value: number | undefined) => (value && !Number.isNaN(value) ? value : undefined);

const NextQuestionLoader: FC<Props> = ({ categoryUrl, categoryText, startTime: position }) => {
  const { startTime, endTime } = useSelector(getNextEventTime);
  const { startTimeProp, endTimeProp, positionProp } = useMemo(
    () => ({
      startTimeProp: isNumber(startTime),
      endTimeProp: isNumber(endTime),
      positionProp: isNumber(position),
    }),
    [startTime, endTime, position],
  );

  return (
    <Box className={'NextQuestionLoader'}>
      {startTimeProp && endTimeProp && positionProp && startTimeProp !== -1 ? (
        <NextQuestionTimeBar
          startTime={startTimeProp}
          endTime={endTimeProp}
          position={positionProp}
        />
      ) : null}
      <CategoryIcon url={categoryUrl} title={categoryText} size="large" color="red" />
    </Box>
  );
};

export default NextQuestionLoader;
