import { AppState } from 'reducers';
import { getSchedule, isAboutToStart } from './episodeScheduleSelectors';

export const getEpisode = (state: AppState) => state.episode;

export const getEpisodeCode = (state: AppState) => {
  const episode = getEpisode(state);

  return episode ? episode.episodeCode : undefined;
};

export const getEpisodeVersion = (state: AppState) => {
  const episode = getEpisode(state);

  return episode ? episode.version : undefined;
};

export const getEpisodeTimingVersion = (state: AppState) => {
  const episode = getEpisode(state);

  return episode ? episode.timingVersion : undefined;
};

export const getEpisodeState = (state: AppState) => {
  const episode = getEpisode(state);

  return episode ? episode.state : undefined;
};

export const isEpisodeClosed = (state: AppState) => getEpisodeState(state) === 'CLOSED';

export const isBreakActive = (state: AppState) => {
  const episode = getEpisode(state);
  const { blockEnded } = getSchedule(state);

  if (!episode) {
    return false;
  }

  const { blockIndex, blocks, canAudioSync } = episode;

  if (blocks.length === 0 || !blocks?.[blockIndex]) {
    return false;
  }

  return blockEnded && blockIndex < blocks.length - 1 && !canAudioSync;
};

export const canJoinEpisode = (state: AppState) => {
  const episode = getEpisode(state);

  if (!episode) {
    return false;
  }

  return /*episode.state === 'WAITING_FOR_SHOW' || */ episode.state === 'BROADCAST';
};

export const canPlayWeekGame = (state: AppState) => {
  const episodeState = getEpisodeState(state);

  return !isAboutToStart(state) && !canJoinEpisode(state) && episodeState === 'OPEN';
};

export const getEpisodeStartDateTime = (state: AppState) => {
  const episode = getEpisode(state);

  if (!episode) {
    return null;
  }

  return episode.broadcastWindowStartDate;
};

export const getEpisodeQuizMaster = (state: AppState) => {
  const episode = getEpisode(state);

  if (!episode) {
    return null;
  }

  return episode.studioPlayers.find((studioplayer) => studioplayer.currentQuizmaster);
};

export const getEpisodeWinner = (state: AppState) => {
  const episode = getEpisode(state);

  if (!episode) {
    return null;
  }

  return episode.studioPlayers.find((studioplayer) => studioplayer.episodeWinner);
};

export const canShowWinner = (state: AppState) => {
  const episode = getEpisode(state);

  if (!episode) {
    return false;
  }

  return !!episode.allowedToShowEpisodeWinner;
};

export const isLeaderboardDone = (state: AppState) => {
  const episode = getEpisode(state);

  if (!episode) {
    return false;
  }

  return !!episode.leaderboardDone;
};

export const getFriendScoresByEpisodeCode = (state: AppState, episodeCode: string) =>
  state.episodeFriendScores
    .filter((friend) => friend.episodeCode === episodeCode)
    .sort((a, b) => (a.score === b.score ? 0 : a.score > b.score ? 1 : -1));

export const getEpisodeTopPlayer = (state: AppState, country: 'DE' | 'AT' = 'AT') => {
  const episode = getEpisode(state);

  if (!episode || !episode.leaderboardPerCountry) {
    return [];
  }

  const leaderboard = episode.leaderboardPerCountry[country];

  if (!leaderboard) {
    return [];
  }

  return leaderboard.top3;
};

export const getEpisodeRankings = (state: AppState, country: 'DE' | 'AT' = 'AT') => {
  const episode = getEpisode(state);

  if (!episode || !episode.leaderboardPerCountry) {
    return {};
  }

  const leaderboard = episode.leaderboardPerCountry[country];

  if (!leaderboard) {
    return {};
  }

  return leaderboard.scoreToRank;
};

export const getCommercialTime = (state: AppState): number | void => {
  const { episode } = state;

  if (!episode) {
    return;
  }

  const { blocks, blockIndex } = episode;

  if (blockIndex === -1) {
    return;
  }

  const block = blocks.find((block) => block.blockIndex === blockIndex);

  if (!block) {
    return;
  }

  const { absoluteStartDate, duration, clientMetaData } = block;
  const { COUNTDOWN } = clientMetaData;

  if (!COUNTDOWN) {
    return;
  }

  const start = absoluteStartDate + duration;
  const end = start + parseInt(COUNTDOWN, 10);

  return end;
};

export const getNumberOfEpisodeRounds = (state: AppState) => getEpisode(state)?.rounds?.length ?? 0;
