import { AppState } from 'reducers';
import {
  CategoryStartEvent,
  Event,
  InteractionEvent,
  RoundEvent,
  StudioPlayerSelectionEvent,
  TriviaEvent,
} from 'types/events';
import { getClientTime } from 'utils/clientTime';
import { getFinishedCategories } from './answersSelectors';
import { getSchedule, getStartTime } from './episodeScheduleSelectors';
import { getEpisode, getNumberOfEpisodeRounds } from './episodeSelectors';

const sortEvents = (a: Event, b: Event) => (a.offset < b.offset ? -1 : 1);

export const getEvents = (state: AppState) => Object.values(state.events);

export const getRoundsByEpisodeCode = (state: AppState, episodeCode: string) =>
  getEvents(state)
    .filter(
      (event): event is RoundEvent => event.type === 'ROUND' && event.episodeCode === episodeCode,
    )
    .sort(sortEvents);

export const getInteractionEvents = (state: AppState, episodeCode: string) =>
  getEvents(state)
    .filter(
      (event): event is InteractionEvent =>
        event.episodeCode === episodeCode &&
        event.type !== 'ROUND' &&
        event.type !== 'CATEGORYSTART',
    )
    .sort(sortEvents);

export const getEvent = (state: AppState, eventId: string): Event | undefined =>
  state.events[eventId];

export const getNextEventTime = (
  state: AppState,
): { startTime: number; endTime?: number; position?: number } => {
  const { events } = state;
  const schedule = getSchedule(state);
  const { nextEventId, currentEventId } = schedule;
  const startTime = getStartTime(state);

  if (!schedule || !nextEventId || !currentEventId) {
    return { startTime: -1 };
  }

  const nextEvent = getEvent(state, nextEventId);
  const currentEvent = getEvent(state, currentEventId);

  if (!nextEvent || !isQuestion(nextEvent.type)) {
    return { startTime: -1 };
  }

  const { offset } = nextEvent;
  let interactionOffset = 0;

  switch (currentEvent?.type) {
    case 'TRIVIA':
    case 'PRACTICEQUESTION': {
      const { phases, offset: currentQuestionOffset } = currentEvent;
      const phase = phases && phases.find((phase) => phase.name === 'FINISHED');

      interactionOffset = phase?.offset || currentQuestionOffset;

      break;
    }

    case 'CATEGORYSTART': {
      const { offset } = events[currentEventId];

      interactionOffset = offset;

      break;
    }
  }

  if (!interactionOffset) {
    return { startTime: -1 };
  }

  const difference = getClientTime() - startTime;

  return { startTime: interactionOffset, endTime: offset, position: difference };
};

export const isQuestion = (type: string) => type === 'TRIVIA' || type === 'PRACTICEQUESTION';

export const isQuestionInteraction = (type: string) =>
  type === 'TRIVIA' || type === 'PRACTICEQUESTION' || type === 'ROUND' || type === 'CATEGORYSTART';

export const getRoundBefore = (
  state: AppState,
  episodeCode: string,
  roundEventId: string,
): RoundEvent | undefined => {
  const roundEvent = getEvent(state, roundEventId);

  if (!roundEvent) {
    return;
  }

  return getRoundsByEpisodeCode(state, episodeCode)
    .filter((event) => event.offset < roundEvent.offset)
    .pop();
};

export const getInteractionsByRound = (
  state: AppState,
  episodeCode: string,
  roundEventId: string,
): InteractionEvent[] => {
  const roundEvent = getEvent(state, roundEventId);

  if (!roundEvent) {
    return [];
  }

  const prevRound = getRoundBefore(state, episodeCode, roundEventId);
  const roundStart = prevRound ? prevRound.offset : 0;

  return getInteractionEvents(state, episodeCode)
    .filter((event) => event.offset >= roundStart && event.offset < roundEvent.offset)
    .sort(sortEvents);
};

export const getInteractionsByCategoryId = (
  state: AppState,
  episodeCode: string,
  categoryId: string,
): InteractionEvent[] => {
  return getInteractionEvents(state, episodeCode)
    .filter(({ content }) => content.categoryId === categoryId)
    .sort(sortEvents);
};

export const getEventsByEpisodeCode = (state: AppState, episodeCode: string) =>
  getEvents(state)
    .filter((event) => event.episodeCode === episodeCode)
    .sort(sortEvents);

export const episodeHasEvents = (state: AppState, episodeCode: string) =>
  !!getEventsByEpisodeCode(state, episodeCode).length;

export const getCategoryStartEvents = (state: AppState) =>
  getEvents(state).filter((event) => event.type === 'CATEGORYSTART') as CategoryStartEvent[];

export const getCategories = (state: AppState) => {
  const categoryStartEvents = getCategoryStartEvents(state);

  return categoryStartEvents.map((event) => ({
    categoryId: event.content.categoryId,
    title: event.content.text,
    imageUrl: getCategoryImageUrl(state, event.content.categoryId),
  }));
};

export const getCategory = (state: AppState, categoryId: string, isPractice?: boolean) => {
  const round = getEvents(state).find(
    (event) => event.type === 'ROUND' && event.content.categoryId === categoryId,
  ) as RoundEvent | undefined;

  if (!round) {
    return undefined;
  }

  const pointRundown = getCategoryPointRundown(state, categoryId, isPractice);

  return {
    categoryId: round.content.categoryId,
    imageUrl: round.content.imageUrl,
    text: round.content.text,
    answerScore: pointRundown.answerScore,
    points: pointRundown.totalPoints,
  };
};

export const getCategoryByEventId = (state: AppState, eventId: string, isPractice?: boolean) => {
  const categoryEvent = getEvent(state, eventId) as CategoryStartEvent;

  if (!categoryEvent || !('categoryId' in categoryEvent.content)) {
    return undefined;
  }

  const round = getEvents(state).find(
    (event) =>
      event.type === 'ROUND' && event.content.categoryId === categoryEvent.content.categoryId,
  ) as RoundEvent | undefined;

  if (!round) {
    return undefined;
  }

  const pointRundown = getCategoryPointRundown(state, categoryEvent.content.categoryId, isPractice);

  return {
    categoryId: round.content.categoryId,
    imageUrl: round.content.imageUrl,
    text: round.content.text,
    answerScore: pointRundown.answerScore,
    points: pointRundown.totalPoints,
  };
};

export const getCategoryImageUrl = (state: AppState, categoryId: string) => {
  const round = getEvents(state).find(
    (event) => event.type === 'ROUND' && event.content.categoryId === categoryId,
  ) as RoundEvent | undefined;

  if (!round) {
    return '';
  }

  return round.content.imageUrl;
};

function isInteraction(type: string) {
  return type === 'PRACTICEQUESTION' || type === 'TRIVIA';
}

export const getQuestionIndexByEventId = (state: AppState, eventId: string) => {
  let event = getEvent(state, eventId);

  if (!event) {
    return {
      index: 0,
      total: 0,
    };
  }

  const events = getEventsByEpisodeCode(state, event.episodeCode);
  const questionEvents = events.filter(({ type }) => isInteraction(type));

  if (!isInteraction(event.type)) {
    const index = events.findIndex(($event) => $event === event);
    event = events.slice(index + 1).find(({ type }) => isInteraction(type));
  }

  const index = questionEvents.findIndex(($event) => $event === event);

  return {
    index: index + 1,
    total: questionEvents.length,
  };
};

export const findStudioPlayerEventByEpisodeCode = (
  state: AppState,
  episodeCode: string,
): StudioPlayerSelectionEvent | undefined => {
  const events = getEventsByEpisodeCode(state, episodeCode);

  const studioPlayerEvent = events.find(({ type }) => type === 'STUDIOPLAYERSELECTION');

  if (!studioPlayerEvent) {
    return undefined;
  }

  return studioPlayerEvent as StudioPlayerSelectionEvent;
};

export const getEventsByType = (state: AppState, type: string) =>
  getEvents(state).filter((event) => event.type === type);

export const getCategoryPointRundown = (
  state: AppState,
  categoryId: string,
  isPractice?: boolean,
) => {
  const triviaEvents = getEvents(state).filter(
    (event) =>
      event.type === (isPractice ? 'PRACTICEQUESTION' : 'TRIVIA') &&
      event.content.categoryId === categoryId,
  ) as TriviaEvent[];

  if (!triviaEvents || !triviaEvents[0]) return { answerScore: 0, totalPoints: 0 };

  const answerScore = triviaEvents[0].content.score;

  const totalPoints = triviaEvents.reduce(
    (totalPoints, event) => (event.content.score ? totalPoints + event.content.score : totalPoints),
    0,
  );

  return {
    answerScore,
    totalPoints,
  };
};

export const getOffsetNextEffect = (state: AppState, nextEventId: string): number | undefined => {
  const event = getEvent(state, nextEventId);

  return event?.offset;
};

// after the second to last round or at the first finaltrivia, submit a score greater than X on a one-time basis
// this is done regardless of episode type, live or pre-recorded.
export const getShouldSubmitGoodscore = (state: AppState, event: InteractionEvent) => {
  const episode = getEpisode(state);

  if (!episode) {
    return false;
  }

  const roundIndex = getFinishedCategories(state).length;
  const numberOfEpisodeRounds = getNumberOfEpisodeRounds(state);

  if (event.type === 'FINALTRIVIA') {
    return true;
  }

  return numberOfEpisodeRounds > 0 && roundIndex >= numberOfEpisodeRounds - 1;
};
