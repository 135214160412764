import { AudioSyncActions } from 'actions/audioSyncActions';
import { EpisodeActions } from 'actions/episodeActions';
import { ScheduleActions } from 'actions/episodeScheduleActions';
import { UserActions } from 'actions/userActions';
import { Reducer } from 'redux';
import { Phase } from 'types/events';
import { getClientTime } from 'utils/clientTime';

export interface EpisodeScheduleItem {
  eventId: string;
  phase: Phase;
}

export interface EpisodeScheduleReducer {
  currentEventId?: string;
  currentEventPhase?: Phase;
  nextEventId?: string;
  previousEventId?: string;
  aboutToStart: boolean;
  syncTime: number;
  offset: number;
  offsetVersion?: number;
  blockEnded: boolean;
  syncType?: 'average' | 'audioSync' | 'audioSyncReset';
  blockIndex: number;
  minSyncConfidence: number;
}

export const initialState: EpisodeScheduleReducer = {
  currentEventId: undefined,
  currentEventPhase: undefined,
  nextEventId: undefined,
  aboutToStart: false,
  syncTime: 0,
  offset: 0,
  blockEnded: true,
  blockIndex: -1,
  minSyncConfidence: 0.8,
};

export const episodeScheduleReducer: Reducer<
  EpisodeScheduleReducer,
  ScheduleActions | AudioSyncActions | EpisodeActions | UserActions
> = (state = initialState, action) => {
  switch (action.type) {
    case '@episodeSchedule/CLEAR':
    case '@episode/CLEAR_EPISODE':
    case '@user/EPISODE_JOINED':
      return initialState;

    case '@episodeSchedule/SET_SCHEDULE':
      return {
        ...state,
        ...action.payload,
      };

    case '@episodeSchedule/SYNC_TYPE':
      return {
        ...state,
        syncType: action.payload,
      };

    case '@episodeSchedule/EMPTY_CURRENT_SCHEDULE_EVENT': {
      return {
        ...state,
        currentEventId: undefined,
        currentEventPhase: undefined,
      };
    }

    case '@audioSync/SET_AUDIO_SYNC': {
      const { confidence = 0, timestamp } = action.payload;

      if (confidence < state.minSyncConfidence || !timestamp) {
        return state;
      }

      return {
        ...state,
        syncType: 'audioSync',
        syncTime: getClientTime(),
        offset: timestamp,
      };
    }

    default:
      return state;
  }
};
